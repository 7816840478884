<template>
  <div class="wrapper ">
    <div class="wrapper__container">
      <jublia-header />
       <div class="container container-transparent">
     
     <!-- <div class="container-ins">-->
        
       <jublia-h1 class="DINBold text-jublia-bluest mb-6 uppercase">
          {{ $t("email-remaind.title") }}
        </jublia-h1>

        <div class="mt-4 w-full">
          <div class="relative flex flex-col  w-full mt-2">
            <div style="margin: auto; margin-bottom: 0.3rem; width: 74%"
            class="relative flex flex-col items-center justify-center w-full mt-2"
            >
             <div
                  style="
                    margin-bottom: 0.5rem;
                    text-align: center;
                    height: auto;
                    padding: 11px;
                   
                  "
                  class="
                    w-full
                    item
                    h-12
                    rounded-lg
                    mt-2
                    px-4
                    text-xl text-jublia-bluest
                    flex  items-center justify-center
                  "
                >
                  <img
                src="@/assets/icons/email-logo.png"
                alt="next button"
                width="100"
                height="18"
              />
              <jublia-p style="font-size:1.8rem;font-weight: 700" 
               class="mt-4 ml-6 text-jublia-blue " > {{ $t("settings-remaind.calander") }}</jublia-p>
                </div>
              <!-- 
                <jublia-p class="text" style="text-align: center !important;font-size:1.1rem;">{{$t('email-remaind.text')}}</jublia-p>

               <input
           
            :tokens="hexTokens"
             @change="updatedate"
            v-model=" date"
           type="date"
            
            name="date"
            class="w-5 text-center  border-2  border-jublia-blue rounded-lg px-2  mt-4 text-jublia-blue w-48"
            placeholder="YYYY-MM-DD"
          />
          -->
           <div
          v-if="(submited2)"
          class="w-full border border-red-500 px-4 py mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('email-remaind.error1') }}</p>
        </div>
            </div>
            <!--<jublia-toggle class="mx-4" :isEnabled="toggle1" v-bind:ids="'refill'" @changet="updateRefill('refill')" > 
               <jublia-p 
               style="width: 59%  !important;font-size:1.3rem"
               class=" text-jublia-bluest text-base mt-6"    > 
               {{ $t("email-remaind.btn") }}</jublia-p>
               </jublia-toggle>
               <jublia-toggle class="mx-4 " :isEnabled="toggle2" v-bind:ids="'remaind'"  @changet="updateRemaind('remaind')" > 
               <jublia-p 
                style="width: 65% !important;font-size:1.3rem"
               class=" text-jublia-bluest  mt-6 "    > 
               {{ $t("email-remaind.btnr") }}</jublia-p>
               </jublia-toggle>-->
              <!-- <jublia-btn class="mx-4 " :isEnabled="subcribe" v-bind:ids="'remaind'"  @changet="updateRefill('reffil')" > 
               <jublia-p 
                style="width: 65% !important;font-size:1.3rem"
               class=" text-jublia-bluest  mt-6 "    > 
               {{ $t("email-remaind.btnb") }}</jublia-p>
               </jublia-btn>-->
                 <jublia-toggle class="mx-4" :isEnabled="toggle2" v-bind:ids="'refill'" @changet="updateRefill('refill')" > 
               <jublia-p 
               style="width: 59%  !important;font-size:1.3rem"
               class=" text-jublia-bluest text-base mt-6"    > 
               {{ $t("sms-remaind.btn") }}</jublia-p>
               </jublia-toggle>
              <!-- <jublia-btn class="mx-4 " :isEnabled="date" v-bind:ids="'remaind'"  @changet="sendDate('remaind')" > 
               <jublia-p 
                style="width: 65% !important;font-size:1.3rem"
               class=" text-jublia-bluest  mt-6 "    > 
               {{ $t("email-remaind.btnr") }}</jublia-p>
               </jublia-btn>-->
               
                <div
          v-if="(submited)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('email-remaind.error') }}</p>
        </div>
         <div
          v-if="(send)"
          class="border border-green-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('email-remaind.SUCCESS') }}</p>
        </div>
          <div
           @click="go(`/error-form-treatment`)"
          v-html="$t('email-remaind.calc')"
          style="text-align: center !important;"
          class="block text-base underline cursor-pointer  text-center mt-14 text text2 lg:w-1/2 md:w-1/2 lg:mx-auto md:mx-auto"
        />
              <!-- <jublia-toggle :isEnabled="toggle2"  v-on:change="toggle($event)">  <jublia-p 
               class=" text-jublia-bluest text-base" > {{ $t("settings-remaind.title") }}</jublia-p>
               </jublia-toggle>-->
            <jublia-button
          :width="true"
           @click="go(`/menu`)"
            class="relative m-6 mx-auto border-jublia-blue font"
           style="text-transform: uppercase; font-size: 1.8rem;"
          >
          <div  style="line-height: 30px !important; " class="mt-2 mb-1">
         {{ $t('user-settings.back') }}
         </div>
          </jublia-button>
          
        <!--  <div class="relative flex justify-between w-full my-8 lg:mx-auto  sm:mx-auto mt-8" >
          <div  class="w-full DINBold flex justify-between items-center mx-4 ">
            <jublia-button 
              @click="sendDate()"
              :disable="!date"
              :bdgray="!date"
              class="w-full flex justify-between items-center">
              <div class="text-xl font-bold flex-grow" style="text-transform: uppercase;" >
                {{ $t('email-remaind.btn') }}
              </div>
              <img
                src="@/assets/icons/next.png"
                alt="next button"
                width="10"
                height="10"
              />
            </jublia-button>
          </div>
            <div  class="w-full DINBold flex justify-between items-center mx-4 "  @click="go">
              <jublia-button  
            
             
            >
              <div class="text-xl DINBold font-bold flex-grow uppercase">
                 {{ $t('user-settings.back') }}
              </div>
              
            </jublia-button></div>
        </div>-->
          </div>

         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JubliaP from "@/components/text/jublia-p";
import JubliaToggle from "@/components/toggle/toggle";
//import JubliaSwitcher from "@/components/toggle/switcher";
import JubliaSwitcher from "@/components/button/btn-send";
import JubliaButton from "@/components/button/jublia-button";
import JubliaH1 from "@/components/text/jublia-h1";
import JubliaH3 from "@/components/text/jublia-h3";

import {mapState, mapActions,mapGetters } from "vuex";
import JubliaHeader from "@/components/header/jublia-header";
import { mask } from 'vue-the-mask'
import {maxLength, minLength} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import moment from 'moment'

export default {
  name: "email-settings",

  components: {
    //JubliaButton,
    "jublia-button": JubliaButton,
    "jublia-header": JubliaHeader,
   "jublia-h3": JubliaH3,
    "jublia-h1": JubliaH1,
    "jublia-p": JubliaP,
    "jublia-toggle":JubliaToggle,
     "jublia-btn":JubliaSwitcher
  },
 data: () => ({
    error: false,
    date: '',
    step:null,
    user: {},
    treatmentProgress: true,
    submited: false,
    submited2: false,
    send: false,
    toggle1:false,
    toggle2:false,
    subcribe:false,
    popup:false,
  hexTokens: {
  A: {
    pattern: /[A-Z]/,
    transform: v => v.toLocaleUpperCase()
  }
}
  
  }),
  directives: {
    mask
  },
    computed: {
    ...mapState({
      url: (state) => state.login.url,
      profil: (state) => state.login.userProfile,
      a: (state) => state.login.token,
      phone: (state) => state.reminder.phone,
      state: (state) => state.login.status,
      reminder: (state) => state.reminder.subscribed,
      nbDays: (state) => state.treatment.nbDays,
      schemaSelection: (state) => state.treatment.schemaSelection,
      typeUser: (state) => state.login.loggedType,
      nbBottles: (state) => state.treatment.nbBottles,
      nbMonthsPrescripted: (state) => state.treatment.nbMonthsPrescripted,
    }),
  ...mapGetters({
     doneCount: 'login/userProfile',
     })
  
  },
  created: function () {
  
   if(!!sessionStorage.getItem('user')){
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
   }
   
   this.toggle2=this.user.emailRefill
  
  },
  mounted() {
      
   if(this.schemaSelection) this.$store.dispatch('treatment/schema',this.schemaSelection);
 },
  setup () {
    return { $v: useVuelidate() }
  },
  methods: {
    ...mapActions({
      update: 'login/updateEmail',
      delete: 'treatment/smallToesInfected'
    }),
    update(name){
      let emailActive=null
      if(name=='refill')emailActive=this.toggle2;
        const datas = {
          email:this.user.email||'',
            emailRefill: this.toggle2,
           emailActive, 
           name
        }

     this.$store.dispatch('login/updateEmail',datas).then(()=>{
           if(this.state!='error'){
            
        
         }
         if(this.state=='error'){
          this.submited = true
          return null
         }
           }).catch((e)=>{
          this.submited = true
        })
    },
     go(a){
     
     this.$router.push(a)
    },
  


 updateRefill(name){
   
   
   this.toggle2=!this.toggle2
    this.update(name)
   //}
   
 },
   validations () {
  return { date: {
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
  }
  }
   }
}
</script>
<style scoped >
.container {

  @media all and (max-width: 768px) {
   
   min-height: 38rem;
  }
}
.container-ins {
  max-width: 870px;
  margin: 0 auto;
  
}

.container-content {
  @apply w-full;
  @apply m-auto;
  @apply pb-8;
 /* @apply px-8;*/
  max-width: 900px;

  @media all and (min-width: 767px) {
    max-width: 500px;
  }
}
input,::placeholder {
  @apply text-jublia-blue;
  
font-size: 1.3rem;
}
</style>
