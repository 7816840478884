<template>
<div class="contain">
   <slot />

    <div class=" flex;
  items-center justify-center pr-4"  >
 
  <jublia-button 
              @click="btns"
              
              :bdgray="!isEnabled"
              style="margin-top: 1.5rem;"
              class="w-full flex justify-between items-center">
              <div class="text-l font-bold flex-grow" style="text-transform: uppercase;font-size: 1rem;" >
                {{ $t('email-remaind.btn') }}
              </div>
              
            </jublia-button>
    </div>
  
    
</div>
</template>

<script>
import JubliaButton from "@/components/button/jublia-button";

export default {
components: {
    //JubliaButton,
    "jublia-button": JubliaButton,
},

  computed: {
      style () {
        return 'background-color: ' + this.color ;
      }
    },
   

  props: {
    isEnabled:{
      type: Boolean,
      default: "false"
    },
     ids: String,
    color: {
      type: String,
      required: '',
      default: "#4D4D4D"
    }
  },
  methods: {
    btns: function() {
     
      // console.log('dfdfdfdf22',this.isEnabled)
      // this.$emit("change",1);
    this.$emit("changet"); 
    }
  }
}
</script>

<style scoped >
.contain {
  @apply flex;
  @apply items-center justify-between;
  @apply w-full;

/*  @apply py-2 ;*/
  @apply min-w-full;
 /* @apply rounded-full;*/
  @apply rounded-2xl;
  @apply text-jublia-white;
 
 @apply font-black;
 @apply text-lg;
 font-size: 1.25rem;
}

input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 77px;
height: 37px;
	background: grey;
	display: block;
	border-radius: 100px;
    margin-bottom: 0.7rem;
	position: relative;
}

label:after {
	content: '';
	position: absolute;
   top: 4px;
    left: 5px;
    width: 30px;
   height: 30px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + label {
	background: #bada55;
}

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 130px;
}

 

</style>
